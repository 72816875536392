<template>
  <div class="semantic">
    <div class="contents">
      <div class="contents-title">Contents</div>
      <ol list-padding-bottom>
        <li><a href="#PrivacyPolicy">Privacy Policy</a></li>
        <li><a href="#CCPAPrivacyPolicyforCaliforniaResidents">CCPA Privacy Policy for California Residents</a></li>
      </ol>
      <Spacer num="1" />
    </div>
    <!-- <PdfVisor v-for="(pdf, index) in pdfList" :pdf="pdf" :key="index" /> -->
    <div v-for="(pdf, index) in pdfList" :pdf="pdf" :key="index" v-html="loadHTML(pdf)"></div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Privacy - My Fans 1A",
  },
  components: {
    PdfVisor: () => import("../widgets/PdfVisor.vue"),
  },
  data: function() {
    return {
      pdfList: ["/docs/MyFans1A.com Privacy Policy.html", "/docs/MyFans1A.com CCPA Privacy Policy for California Residents.html"],
    };
  },
};
</script>
